/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import SvgIcon from '../../atoms/SvgIcon';
import BankIDLogoDefault from '../../../js/images/BankID_logo.svg';

import { useOptions } from '../../../context/options';
import LinkBlock from '../../blocks/LinkBlock';
import ImageBlock from '../../blocks/ImageBlock';
import LanguageSwitch from '../LanguageSwitch';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import { brand } from '../../../js/localizations/current-locale';
import { sendEvent } from '../../../js/utils/gtm';

import devices from '../../../js/styles/Devices';
import useDevice from '../../../js/utils/use-device';
import style from './style';

const HeaderWrapper = styled.header`
  && {
    font-size: 0.9rem;
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    display: flex;
    padding: 0.5rem 0;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    justify-content: space-between;
    background-color: ${style.backgroundColor};
    box-shadow: ${style.boxShadow};
    height: 4rem;

    @media ${devices.downFromLargeTablet} {
      padding: ${style.headerWrapperPadding};
    }
  }
`;

const Logo = styled(ImageBlock)`
  && {
    margin-left: 0.25rem;
    height: 2rem;
    width: 11rem;

    @media ${devices.downFromHub} {
      width: 7rem;
    }

    @media ${devices.downFromLargeTablet} {
      width: 11rem;
    }
  }
`;

const HeaderMenuWrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    color: ${style.linkColor};
    z-index: 10;

    @media ${devices.downFromLargeTablet} {
      display: none;
    }
  }
`;

const HeaderMenuWrapperMobile = styled.div`
  && {
    @media ${devices.downFromLargeTablet} {
      z-index: 10;
      flex-direction: column;
      text-align: start;
      gap: 0;
      margin-right: 0;
      margin-top: 4rem;
      display: flex;
      overflow: hidden;
      position: absolute;
      left: 2rem;
      width: 85%;
    }
  }
`;

const MobileFlexCol = styled.div`
  && {
    display: flex;
    position: sticky;
    flex-direction: column;
    align-items: center;
    z-index: 99;

    width: 100%;
    height: 100%;
    background: ${style.backgroundColor};

    @media ${devices.upFromLargeTablet} {
      display: none;
    }
  }
`;

const PhoneNumberBlock = styled(LinkBlock)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${style.textBgColor};
    text-decoration: none;

    path {
      fill: ${style.phoneNumberIconColor};
    }

    @media ${devices.downFromLargeTablet} {
      display: block;
    }
  }
`;

const Text = styled.div`
  && {
    display: ${(props) => (props.shouldDisplay ? 'flex' : 'none')};
    padding-top: 1px;
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0.75rem')};
    justify-content: center;
    align-items: center;
    height: 100%;
    letter-spacing: 0;

    ${() => (brand === 'digifinans' && css`
      @media ${devices.isLargeHub} {
        display: none;
      }
    `)}

    ${(props) => (props.type === 'loginMobile' && css`
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      height: 100%;
    `)}

    ${(props) => (props.type === 'phoneNumberMobile' && css`
      font-size: 2rem;
      padding-left: 5px;

      @media ${devices.downFromLargeTablet} {
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 0;
      }
    `)}

    ${(props) => (props.type === 'phoneNumberDesktop' && css`
      padding-left: 5px;
      letter-spacing: 0;
    `)}
  }
`;

const LoginContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      text-decoration: none;
      color: var(--dark-teal);
      color: ${style.loginButtonTextColor};
      font-size: 0.7rem;
      padding: 0.2rem;
      padding-left: 1.9rem;
      padding-right: 1.9rem;
      border-radius: ${style.loginButtonBorderRadius};
    }

    a {
      font-size: 0.7rem;
      padding: ${(props) => (props.isBankID ? '0.3rem' : '0.7rem')};
      padding-left: 2.2rem;
      padding-right: 2.2rem;

      text-align: center;
      color: ${style.loginIconColor};
      font-weight: 700;

      @media ${devices.downFromDesktop} {
        padding-left: 1rem;
        padding-right: 1rem;
        min-width: 0;
      }
    }

    path {
      fill: ${style.loginIconColor};
    }

    @media ${devices.downFromLargeTablet} {
      width: 100%;

      a {
        font-size: 0.7rem;
        padding: ${(props) => (props.isBankID ? '0.3rem' : '0.7rem')};
        padding-left: 1.9rem;
        padding-right: 1.9rem;
        min-width: 100%;
        text-align: center;
        color: ${style.loginIconColor};
      }

      path {
        fill: ${style.loginIconColor};
      }
    }

    svg {
      font-size: 0.7rem;
    }
  }
`;

const LoginBlock = styled(LinkBlock)`
  && {
    display: flex;
    font-size: 0.5rem;
    text-decoration: none;
    width: 100%;
  }
`;

const Icon = styled(SvgIcon)`
  && {
    margin-top: ${(props) => props.marginTop};
  }
`;

const MenuListLink = styled(LinkBlock)`
  && {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-left: 2rem;
    color: ${(props) => (props.isActive ? style.activeLinkColor : style.linkColor)};
    font-size: 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    text-decoration: ${(props) => (props.isActive ? 'underline' : 'none')};
    white-space: ${(props) => (props.noWrap ? 'normal' : 'unset')};
    white-space: nowrap;

    &:hover {
      color: ${style.activeLinkColor};
      text-decoration: none;
    }

    @media ${devices.upFromTablet} {
      display: flex;
      padding: 0.5em 0;
    }

    @media ${devices.downFromLargeTablet} {
      display: none;
    }
  }
`;

const MenuListLinkMobile = styled(LinkBlock)`
  && {
    @media ${devices.downFromLargeTablet} {
      font-weight: 600;
      font-size: 1.5rem;
      text-decoration: none;
      color: ${(props) => (props.isActive ? style.activeLinkColor : style.linkColor)};
      text-decoration: ${(props) => (props.isActive ? 'underline' : '')};
      display: block;
      padding: 0.6em 0;
    }
  }
`;

const LanguageSwitchWrapper = styled(LanguageSwitch)`
  && {
    align-self: center;
    height: fit-content;
    margin-left: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;

    @media ${devices.downFromLargeTablet} {
      margin-left: 0;
      font-size: 1rem;

      img {
        height: 1.2rem;
        width: 2rem;
      }
    }
  }
`;

const Button = styled.div`
  && {
    color: ${style.mobileMenuButtonColor};
    outline: none;
    text-decoration: none;
    display: inline-flex;
    padding-left: ${(props) => (props.mobileMenuOpen ? '0.5rem' : '0')};
    padding-right: 0.5rem ;
    margin-bottom: 0.2rem;

    svg {
      width:  ${(props) => (props.mobileMenuOpen ? style.buttonSvgWidthHeight : '1rem')};
      height:  ${(props) => (props.mobileMenuOpen ? style.buttonSvgWidthHeight : '1rem')};
      font-size: ${(props) => (props.mobileMenuOpen ? '1.33rem' : '1.2rem')};
    }

    @media ${devices.upFromLargeTablet} {
      display: none;
    }

    @media ${devices.downFromDesktop} {
      margin: 0.5rem;
      margin-top: ${style.menuMarginTopMobile};
    }

    @media ${devices.downFromLargeTablet} {
      &:hover {
        background-color: ${style.buttonHoverBg};
        padding: ${style.buttonHoverPadding};
        margin: ${style.buttonHoverMargin};
      }

      svg {
        width: ${style.buttonSvgWidthHeight};
        height: ${style.buttonSvgWidthHeight};
      }
    }
  }
`;

const MobileMenu = styled.ul`
  && {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${style.mobileMenuBg};
    font-family: ${style.fontFamily};
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 10;
    display: ${(props) => ((props.mobileMenuOpen) ? 'flex' : 'none')};

    @media ${devices.upFromLargeTablet} {
      display: flex;
      position: static;
      flex-grow: 1;
      background: transparent;
      border-radius: 0;
      border-top: 0;
      text-align: right;
    }

    @media ${devices.downFromLargeTablet} {
      top: 0;
      margin-top: 2rem;
    }
  }
`;

const FlexCol = styled.div`
  && {
    display: flex;
    width: 100%;
    align-items: center;

    ${MobileMenu} {
      @media ${devices.downFromLargeTablet} {
        display: flex;
      }
    }
  }
`;

const OverLayMobile = styled.div`
  && {
    &::before {
      position: fixed;
      top: 3rem;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${style.overlayBackgroundColor};
      content: '';
      z-index: 5;
      inset: 3rem 0 0;
      min-width: 100vw;
      width: 100vw;
      max-width: 100vw;

      @media ${devices.upFromLargeTablet} {
        top: 5rem;
      }

      @media ${devices.upFromLargeTablet} {
        display: none;
      }

      @media ${devices.downFromDesktop} {
        top: 0;
      }
    }
  }
`;

const LanguageContentMobile = styled.div`
  && {
    display: flex;
    justify-content: end;
  }
`;

const LanguageContentDesktop = styled.div`
  && {
    @media ${devices.downFromLargeTablet} {
      display: none;
    }
  }
`;

const NavigationSection = styled.div`
  && {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: start;

    @media ${devices.downFromDesktop} {
      width: 100%;
    }
  }
`;
const UtilitySection = styled.div`
  && {
    display: flex;
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: end;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const BankIdLogoStyledDefault = styled(BankIDLogoDefault)`
  && {
    margin-left: 0;
    display: inline-block;
    position: relative;
    line-height: normal;
    width: 2.5rem;

    @media ${devices.downFromLargeTablet} {
      width: 3rem;
    }

    ${brand === 'sambla' && css`
      filter: brightness(0) invert(1);
    `}
  }
`;

const MobileUtilitySectionContainer = styled.div`
  && {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
  }
`;

const MobileUtilitySectionFirstRow = styled.div`
  && {
    display: flex;
    width: 100%;
    padding: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-items: center;
  }
`;
const MobileUtilitySectionSecondRow = styled.div`
  && {
    padding: 0.5rem 1rem 1.5rem 1rem;
    text-align: left;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

  }
`;

const MobileUtilitySectionRowContainer = styled.div`
  && {
    ${(props) => (props.type === 'first' && css`
      & > * {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
      }
    `)}

    ${(props) => (props.type === 'second' && css`
      display: flex;
      align-items: center;
      text-align: right;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      justify-content: end;
    `)}
  }
`;

const PureHeader = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    logo = {
      link: {},
      image: {},
    },
    menu,
    loginOption,
    showLoginOption,
    showPhoneNumber,
    callOption,
    showIcon,
    phone_number,
    languageSwitchLabel,
    showChatbot,
  } = props.header || {};

  const { link, image } = logo;
  const {
    translations,
  } = props;

  const {
    isLargeTabletAndDown, isLargeTabletAndDownHeader, isLaptop,
  } = useDevice();

  const trackClick = (path) => {
    switch (path) {
      case 'openMobileMenu':
        sendEvent({
          event: 'user_globalNavi_click',
          content_id: 'openNavMenu',
          component_name: 'header',
        });
        break;
      case 'callButton':
        sendEvent({
          event: 'user_helpPhone_click',
          component_name: 'header',
        });
        break;
      case 'login':
        sendEvent({
          event: 'user_login_click',
          content_id: 'myPages/customerPortal',
          component_name: 'header',
        });
        break;
      default:
        sendEvent({
          event: 'user_globalNavi_click',
          content_id: new URL(path).pathname.replace(/^\/+|\/+$/g, ''),
          component_name: 'header',
        });
        break;
    }
  };

  useEffect(() => {
    // Disable scrolling while mobile menu is open
    if (mobileMenuOpen) {
      window.document.body.style.overflow = 'hidden';
      trackClick('openMobileMenu');
    } else {
      window.document.body.style.overflow = 'auto';
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showChatbot) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://bot.leadoo.com/bot/dynamic.js?company=nJlezQdn#zindex=100';
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [showChatbot]);

  const getIsMenuLinkActive = (item) => {
    if (typeof window === 'undefined') return false;

    return item.link.url === window.location.href;
  };
  const LogoBlock = () => (
    <LinkBlock link={{ url: link?.link?.url }}>
      <Logo imageObject={image} />
    </LinkBlock>
  );

  const HeaderMenu = () => (
    <>
      <HeaderMenuWrapper>
        {menu && menu.map((item, key) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={(e) => { e.stopPropagation(); trackClick(item.link.url); }}>
            <MenuListLink
              {...item}
              key={key}
            />
          </div>
        ))}
      </HeaderMenuWrapper>
    </>
  );

  const MobileHeaderMenu = () => (
    <>
      <HeaderMenuWrapperMobile>
        {menu?.length > 0 && (
          menu.map((item, key) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div onClick={(e) => { e.stopPropagation(); trackClick(item.link.url); }}>
              <MenuListLinkMobile
                {...item}
                key={key}
                isActive={getIsMenuLinkActive(item)}
                onClick={(e) => { e.stopPropagation(); trackClick(item.link.url); }}
              />
            </div>
          )))}
        <MobileUtilitySection />
      </HeaderMenuWrapperMobile>
    </>
  );

  // eslint-disable-next-line no-shadow
  const HeaderLogin = (props) => (
    <>
      <LoginContainer
        isMobile={isLargeTabletAndDownHeader}
        isBankID={!loginOption.icon}
        onClick={() => trackClick('login')}
      >
        <LoginBlock
          fontFamily={getFontFamily(style.fontFamily)}
          link={{
            url: loginOption.link.url,
            target: loginOption.link.target,
          }}
          aria-label="Login"
          type="bankID"
          isMobile={!isLargeTabletAndDown}
        >
          {loginOption.icon && (
            <Icon
              icon={loginOption.icon[0].split('/')}
              display={style.loginIconDisplay}
              size={isLargeTabletAndDownHeader ? 'sm-md-mobile' : 'sm-md-desktop'}
            />
          )}
          {!loginOption.icon && (
            <BankIdLogoStyledDefault />
          )}

          <Text
            shouldDisplay
            // eslint-disable-next-line react/prop-types
            type={props.type}
            paddingLeft={!loginOption?.icon[0] ? '0.2rem' : null}
            isBankID={!loginOption?.icon[0]}
          >
            {loginOption.link.title}
          </Text>
        </LoginBlock>
      </LoginContainer>
    </>
  );

  // eslint-disable-next-line no-shadow
  const HeaderPhoneNumberBlock = (props) => (
    <>
      {showPhoneNumber && showIcon && (
      <PhoneNumberBlock
        as="a"
        href={`tel:${phone_number}`}
        onClick={() => trackClick('callButton')}
      >
        {callOption.icon[0] && (
        <Icon
          icon={callOption.icon[0].split('/')}
          display={style.loginIconDisplay}
          size={isLargeTabletAndDownHeader ? 'sm-md-mobile' : 'sm-md-desktop'}
        />
        )}
        <Text
          // eslint-disable-next-line react/prop-types
          shouldDisplay={(!isLaptop || isLargeTabletAndDownHeader)}
          // eslint-disable-next-line react/prop-types
          type={props.type}
        >
          {phone_number}
          {' '}
        </Text>
      </PhoneNumberBlock>
      )}
    </>
  );

  const HeaderLanguageSwitch = () => (
    <LanguageContentDesktop>
      <LanguageSwitchWrapper
        label={languageSwitchLabel}
        translations={translations}
        selectInHeader
      />
    </LanguageContentDesktop>
  );

  const MobileHeaderLanguageSwitch = () => (
    <LanguageContentMobile>
      <LanguageSwitchWrapper
        label={languageSwitchLabel}
        translations={translations}
        selectInHeader
      />
    </LanguageContentMobile>

  );

  const MobileMenuToggle = () => (
    <Button
      role="button"
      tabIndex={0}
      mobileMenuOpen={mobileMenuOpen}
      aria-label="Menu"
      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
    >
      <Icon
        icon={['fal', mobileMenuOpen ? 'times' : 'bars']}
        size={mobileMenuOpen && 'lg'}
      />
    </Button>
  );

  const MobileUtilitySection = () => (
    <MobileUtilitySectionContainer>
      <MobileUtilitySectionFirstRow>
        <MobileUtilitySectionRowContainer type="first">
          <HeaderPhoneNumberBlock type="phoneNumberMobile" />
        </MobileUtilitySectionRowContainer>
        <MobileUtilitySectionRowContainer type="second">
          <MobileHeaderLanguageSwitch />
        </MobileUtilitySectionRowContainer>
      </MobileUtilitySectionFirstRow>
      <MobileUtilitySectionSecondRow>
        {showLoginOption && <HeaderLogin type="loginMobile" />}
      </MobileUtilitySectionSecondRow>
    </MobileUtilitySectionContainer>
  );

  /* eslint-enable */
  return (
    <>
      <HeaderWrapper id="page-render-confirmation-element">
        <FlexCol>
          <NavigationSection>
            <LogoBlock />
            {!isLargeTabletAndDownHeader && (<HeaderMenu />)}
          </NavigationSection>
          <UtilitySection>
            {isLoaded === true && isLargeTabletAndDownHeader === false && (<HeaderPhoneNumberBlock type="phoneNumberDesktop" />)}
            {isLoaded === true
            && isLargeTabletAndDownHeader === false && (<HeaderLanguageSwitch />)}
            {isLoaded === true && isLargeTabletAndDownHeader === false && showLoginOption && (<HeaderLogin type="loginDesktop" />)}
            <MobileMenuToggle />
          </UtilitySection>
        </FlexCol>
      </HeaderWrapper>
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        fontFamily={getFontFamily(style.fontFamily)}
      >
        <MobileFlexCol>
          <OverLayMobile />
          <MobileHeaderMenu />
        </MobileFlexCol>
      </MobileMenu>
    </>
  );
};

PureHeader.propTypes = {
  header: PropTypes.shape({
    logo: PropTypes.shape({
      image: ImageBlock.propTypes.imageObject,
      link: PropTypes.shape(LinkBlock.propTypes),
    }).isRequired,
    menu: PropTypes.arrayOf(PropTypes.shape(LinkBlock.propTypes)).isRequired,
    phone_number: PropTypes.string,
    phoneText: PropTypes.string,
    loginOption: PropTypes.shape(LinkBlock.propTypes),
    languageSwitch: PropTypes.string,
    languageSwitchLabel: PropTypes.string,
    backgroundColor: PropTypes.string,
    linkColor: PropTypes.string,
    linkPosition: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
  }).isRequired,
  translations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PureHeader.defaultProps = {};

const Header = (props) => {
  const {
    optionHeader,
  } = useOptions();

  const isTestHeaderEnabled = useFeatureIsOn('test-header-onb');
  useEffect(() => {
    if (isTestHeaderEnabled) {
      console.log('Test header is enabled');
    } else {
      console.log('Test header is disabled');
    }
  }, [isTestHeaderEnabled]);

  return (
    <PureHeader
      {...props}
      header={optionHeader}
    />
  );
};

export { PureHeader };
export default Header;
